import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./advice-details-view.html";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";
import * as moment from "moment";
import { MainLayout } from "./main-layout";

@injectable()
export class AdviceDetailsView {
    private _umbracoApiClient: UmbracoApiClient;
    private _translationService: TranslationService;
    private _translations: any;

    public constructor(
        umbracoApiClient: UmbracoApiClient,
        translationService: TranslationService,
        private currentUserAccessor: CurrentUserAccessor) {
        this._umbracoApiClient = umbracoApiClient;
        this._translationService = translationService;
        this._translations = null;
    }

    public async load(layout: MainLayout): Promise<void> {
        try {
            var url = window.location.href.split("/");
            //var nasvetId = Number(url[url.length - 1]);
            var nasvet = await this._umbracoApiClient.getNasvetByUrl(url[url.length - 1]);
            if (nasvet) {
                layout.pageTitle = nasvet.Naslov;
                layout.htmlTitle = nasvet.Naslov;
            }
            await this._renderData(nasvet);

        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _renderData(nasvet: any): Promise<void> {
        // Build a view model from the API data
        this._translations = this._translationService.currentTranslations;

        var currentUser = await this.currentUserAccessor.getUser();
        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            "imageKuponUrl": "../img/icon-kuponi.png",
            "imageKodaUrl": "../img/koda.jpg",
            "nasvet": nasvet,
            formators: this.getFormators(),
            translations: this._translations,
            currentUser: currentUser
        } as any;
        
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);

        // Set SEO
        $("#meta-description")[0].setAttribute("content", nasvet.SeoDescription);
        $("#meta-og-title")[0].setAttribute("content", nasvet.SeoTitle);
        $("#meta-og-description")[0].setAttribute("content", nasvet.SeoDescription);
        $("#meta-og-image")[0].setAttribute("content", nasvet.SeoImage);
        $("#meta-twitter-title")[0].setAttribute("content", nasvet.SeoTitle);
        $("#meta-twitter-description")[0].setAttribute("content", nasvet.SeoDescription);
        $("#meta-twitter-image")[0].setAttribute("content", nasvet.SeoImage);
        var currentUrl = window.location.href;
        $("#meta-og-url")[0].setAttribute("content", currentUrl);
        $("#meta-twitter-url")[0].setAttribute("content", currentUrl);
    }

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'sl';
        moment.locale(userLocale);
        return {
            dayFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('dddd');
                };
            },
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('DD.MM.YYYY');
                };
            },
            fullDateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('yyyy-MM-DD');
                };
            },
            decimalFormat: function () {
                return function (timestamp: any, render: any) {
                    var decimal = render(timestamp).trim() as number;
                    return Number(decimal).toLocaleString('sl-SI');
                };
            }
        };
    }
}
