import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./registracija-drugi-korak-view.html";
import { HomeApiClient } from "../ts/clients/home-api-client";
import { UserApiClient } from "../ts/clients/users-api-client";
import { RegistracijaDrugiKorakModel } from "../ts/models/registracija-drugi-korak-model";
import "jquery-validation-unobtrusive";
import { Notyf } from "notyf";

@injectable()
export class RegistracijaDrugiKorakView {
    private _homeApiClient: HomeApiClient;
    private _userApiClient: UserApiClient;
    private _translationService: TranslationService;
    private _translations: any;
    private _formElement: JQuery<HTMLElement> | null;

    public constructor(homeApiClient: HomeApiClient,
        userApiClient: UserApiClient,
        translationService: TranslationService,
        private _notyf: Notyf) {
        this._homeApiClient = homeApiClient;
        this._userApiClient = userApiClient;
        this._translationService = translationService;
        this._translations = null;
        this._formElement = null;
    }

    public async load(): Promise<void> {
        try {
            var response = await this._homeApiClient.get();
            await this._renderData(response.data);
            this._initForm();

        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _renderData(data: string): Promise<void> {

        // Build a view model from the API data
        this._translations = this._translationService.currentTranslations;

        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            "imageKuponUrl": "../img/icon-kuponi.png",
            "imageKodaUrl": "../img/koda.jpg",
            "text": data,
            translations: this._translations,
        } as any;

        // Construct a template
        const htmlTemplate = template;
  
        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);

    }

    private _initForm() {
        this._formElement = $(("#registracija-form"));
        $.validator.unobtrusive.parse(this._formElement);
        this._formElement.validate();
        
        var btnNaprej = $("#btn-naprej");
        if (!btnNaprej) return;
        btnNaprej.on("click", (ev: Event) => this._onBtnNaprejClick(ev, this._formElement));
        
        
    }

    private async _onBtnNaprejClick(ev: Event, formElement: any) {
        ev.preventDefault();
        if (!formElement.valid()) {
            return;
        }
        var formData = new FormData(formElement[0] as HTMLFormElement);

        var ime = formData.get("ime") as string;
        var priimek = formData.get("priimek") as string;
        var spol = Number(formData.get("spol") as string);

        var data: RegistracijaDrugiKorakModel = {
            ime: ime,
            priimek: priimek,
            spol: spol
        };
        await this._userApiClient.registracijaDrugiKorakShrani(data)
            .then((odgovor: boolean) => {
                if (odgovor) {
                    window.location.href = "#/registracija-tretji-korak";
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }
}