import { Container } from "inversify";
import { ErrorCodes } from "../error-handling/error-codes";
import { ErrorConsoleReporter } from "../error-handling/error-console-reporter";
import { ErrorFactory } from "../error-handling/error-factory";
import { Authenticator } from "./authenticator";

export class OpenIdLogoutHandler {
	public static async handleLogout(serviceProvider: Container): Promise<boolean> {
		const authenticator = serviceProvider.get<Authenticator>("Authenticator");

		try {
			// If the page loads with a state query parameter we classify it as an OAuth response
			const urlData = new URL(location.href);
			const state = urlData.searchParams.get("state");
			if (state) {

				// Start processing of the authorization response on the iframe
				// Any errors are reported via the authenticator class of the main window
				await authenticator._userManager.signinSilentCallback();
			}

		} catch (e) {

			// In the event of errors, avoid impacting end users and output the error to the console
			const uiError = ErrorFactory.getFromTokenError(e, ErrorCodes.tokenRenewalError);
			ErrorConsoleReporter.output(uiError);
		}

		return false;
	}
}