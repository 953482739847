import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { RideDto } from "../models/ride-dto";
import { SkupneZmageStatsResponse } from "../models/skupne-zmage-stats-response";
import { TotalStatsResponse } from "../models/total-stats-response";
import { TrenutniIzzivStatsResponse } from "../models/trenutni-izziv-stats-response";
import { UserStatsResponse } from "../models/user-stats-response";

@injectable()
export class RidesApiClient extends ApiClientBase {
    public name = "RidesApiClient";
    public constructor(

        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getTotalStats(): Promise<Array<TotalStatsResponse>> {
        return await this._callApi('/Rides/GetTotalStats', 'GET', null, false) as Array<TotalStatsResponse>;
    }

    public async getTrenutniIzzivStats(): Promise<TrenutniIzzivStatsResponse> {
        return await this._callApi('/Rides/GetTrenutniIzzivStats', 'GET', null, false) as TrenutniIzzivStatsResponse;
    }

    public async getSkupneZmageStats(): Promise<SkupneZmageStatsResponse> {
        return await this._callApi('/Rides/GetSkupneZmageStats', 'GET', null, false) as SkupneZmageStatsResponse;
    }

    public async getUserStats(): Promise<Array<UserStatsResponse>> {
        return await this._callApi('/Rides/GetUserStats', 'GET', null, true) as Array<UserStatsResponse>;
    }

    public async getUserChallengeStats(): Promise<Array<UserStatsResponse>> {
        return await this._callApi('/Rides/GetUserChallengeStats', 'GET', null, true) as Array<UserStatsResponse>;
    }

    public async getSkupneZmageStatsByDate(dateFrom: Date, dateTo: Date): Promise<SkupneZmageStatsResponse> {
        return await this._callApi('/Rides/GetSkupneZmageStatsByDate/' + dateFrom + "/" + dateTo, 'GET', null, false, "") as SkupneZmageStatsResponse;
    }

    public async getRides(): Promise<Array<RideDto>> {
        return await this._callApi('/Rides/GetRides', 'GET', null, true) as Array<RideDto>;
    } 

    public async addOrUpdateRides(data: any): Promise<any> {      
        return await this._callApi('/Rides/AddOrUpdateRide', 'POST', JSON.stringify(data), true, "") as any;
    }

    public async delete(id: string): Promise<any> {
        return await this._callApi('/Rides/Delete/' + id, 'DELETE', true) as any;
    }

    public async getBadges(): Promise<any> {
        return await this._callApi('/Rides/GetBadges', 'GET', true) as any;
    }

    public async getImage(): Promise<any> {
        return await this._downloadFile(`/Rides/GetUserStatsImage`) as any;
    }
}