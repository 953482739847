import { NavigationNode } from "../models/navigation-node";

export class SiteMapManager {

    constructor(private rootNodes: Array<NavigationNode>) {

    }

    public getNodesForUser(permissions: number): Array<NavigationNode> {
        return this.getNodesForUserInternal(permissions, this.rootNodes);
    }

    private getNodesForUserInternal(permissions: number, childNodes: Array<NavigationNode>): Array<NavigationNode> {
        var result = new Array<NavigationNode>();
        for (var i = 0; i < childNodes.length; i++) {            
            var currentNode = childNodes[i];
            if (currentNode.permissions && (currentNode.permissions & permissions) == 0) {
                continue; //nima dostopa
            }

            if (!currentNode.hasChildren || !currentNode.children) {
                result.push(currentNode);
                continue; //nima otrok, objekt se ne bo spreminjal
            }

            //skopiramo
            var newChildNodes = this.getNodesForUserInternal(permissions, currentNode.children);
            var newNode: NavigationNode = {
                children: newChildNodes,
                class: currentNode.class,
                hasChildren: true,
                permissions: currentNode.permissions,
                title: currentNode.title,
                url: currentNode.url
            };
            result.push(newNode);
        }

        return result;

    }


}