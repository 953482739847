import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { NotificationDto } from "../models/notification-dto";
import { NotificationResponse } from "../models/notification-response";

@injectable()
export class NotificationsApiClient extends ApiClientBase {
    public name = "NotificationsApiClient";
    public constructor(

        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getNotification(id: number): Promise<NotificationResponse> {
        return await this._callApi('/Notifications/GetNotification/' + id, 'POST', null, false) as NotificationResponse;
    }
    public async getNotifications(search: string, skip: number, take: number): Promise<Array<NotificationDto>> {
        return await this._callApi(`/Notifications/GetNotifications/${search}/${skip}/${take}`, 'POST', null, false) as Array<NotificationDto>;
    }
    public async getNotificationsByUser(userId: number, skip: number, take: number): Promise<Array<NotificationDto>> {
        return await this._callApi(`/Notifications/GetNotification/${userId}/${skip}/${take}`, 'POST', null, false) as Array<NotificationDto>;
    }
    public async getUnreadNotificationsByUser(): Promise<Array<NotificationDto>> {
        return await this._callApi(`/Notifications/GetUnreadNotifications`, 'POST', null, false) as Array<NotificationDto>;
    }
    public async createNotification(notification: NotificationDto): Promise<NotificationResponse> {
        return await this._callApi(`/Notifications/CreateNotification`, 'POST', notification, false) as NotificationResponse;
    }
    public async updateNotification(notification: NotificationDto): Promise<NotificationResponse> {
        return await this._callApi(`/Notifications/UpdateNotification`, 'POST', notification, false) as NotificationResponse;
    }
    public async deleteNotification(id: number): Promise<NotificationResponse> {
        return await this._callApi('/Notifications/DeleteNotification/' + id, 'POST', null, false) as NotificationResponse;
    }
    public async publishNotification(id: number): Promise<NotificationResponse> {
        return await this._callApi('/Notifications/PublishNotification/' + id, 'POST', null, false) as NotificationResponse;
    }
}