import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./nagrade-view.html";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";
import { RidesApiClient } from "../ts/clients/rides-api-client";
import * as moment from "moment";
import { MailchimpApiClient } from "../ts/clients/mailchimp-api-client";
import { Notyf } from "notyf";
import { FullscreenLoader } from "./fullscreen-loader";
import { HelpModel } from "../ts/models/help-model";
import helpModal from "./help-modal.html";
import { createPopper } from '@popperjs/core';

@injectable()
export class NagradeView {
    private _umbracoApiClient: UmbracoApiClient;
    private _ridesApiClient: RidesApiClient;
    private _translationService: TranslationService;
    private _translations: any;

    public constructor(umbracoApiClient: UmbracoApiClient,
        ridesApiClient: RidesApiClient,
        translationService: TranslationService,
        private currentUserAccessor: CurrentUserAccessor,
        private mailchimpApiClient: MailchimpApiClient,
        private notyf: Notyf,
        private loader: FullscreenLoader) {
        this._umbracoApiClient = umbracoApiClient;
        this._ridesApiClient = ridesApiClient;
        this._translationService = translationService;
        this._translations = null;
    }

    public async load(): Promise<void> {
        try {
            var nagrade = await this._umbracoApiClient.getNagradePage();
            var skupneZmageStats = await this._ridesApiClient.getSkupneZmageStats();
            await this._renderData(nagrade, skupneZmageStats);
            this._initButtons();
            const popcorn = document.querySelectorAll('#tooltipParent');
            const tooltip = document.querySelectorAll('#tooltip');
            if (popcorn && tooltip) {
                for (var i = 0; i < popcorn.length; i++) {
                    createPopper(popcorn[i], tooltip[i] as HTMLElement, {
                        placement: 'top-start',
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ],
                    });
                }
            }
        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _renderData(nagrade: any, skupneZmageStats: any): Promise<void> {

        // Build a view model from the API data
        this._translations = this._translationService.currentTranslations;

        var currentUser = await this.currentUserAccessor.getUser();
        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            "imageKuponUrl": "../img/icon-kuponi.png",
            "imageKodaUrl": "../img/koda.jpg",
            translations: this._translations,
            currentUser: currentUser,
            data: nagrade,
            skupneZmage: skupneZmageStats,
            formators: this.getFormators()
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);

        // Set SEO
        $("#meta-description")[0].setAttribute("content", nagrade.SeoDescription);
        $("#meta-og-title")[0].setAttribute("content", nagrade.SeoTitle);
        $("#meta-og-description")[0].setAttribute("content", nagrade.SeoDescription);
        $("#meta-og-image")[0].setAttribute("content", nagrade.SeoImage);
        $("#meta-twitter-title")[0].setAttribute("content", nagrade.SeoTitle);
        $("#meta-twitter-description")[0].setAttribute("content", nagrade.SeoDescription);
        $("#meta-twitter-image")[0].setAttribute("content", nagrade.SeoImage);
        var currentUrl = window.location.href;
        $("#meta-og-url")[0].setAttribute("content", currentUrl);
        $("#meta-twitter-url")[0].setAttribute("content", currentUrl);
    }

    public getFormators() {
        const userLocale = document.documentElement.lang
            ? document.documentElement.lang
            : 'sl';
        moment.locale(userLocale);
        return {
            dayFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('dddd');
                };
            },
            dateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('D.M.');
                };
            },
            fullDateFormat: function () {
                return function (timestamp: any, render: any) {
                    return moment(render(timestamp).trim()).format('yyyy-MM-DD');
                };
            },
            decimalFormat: function () {
                return function (timestamp: any, render: any) {
                    var decimal = render(timestamp).trim() as number;
                    return Number(decimal).toLocaleString('sl-SI');
                };
            }
        };
    }

    private _initButtons(): void {
        var btnStik = $(".btn-stopite-v-stik");
        btnStik[0].setAttribute("href", "javascript:void(0)");
        btnStik.on("click", async () => {
            await this._initHelp();
        });
    }

    private async _initHelp(): Promise<void> {
        var self = this;

        const helpViewModel = {} as any;

        const modalHtml = Mustache.render(helpModal, helpViewModel);
        $('#helpWrap').html(modalHtml);

        $('#helpModal').modal("show");

        var formElement = $("#helpForm");
        $.validator.unobtrusive.parse(formElement);
        formElement.validate();

        formElement.on("submit", function (ev) {
            ev.preventDefault();
            if (!formElement.valid()) {
                return;
            }

            var request: HelpModel = {
                name: formElement.find("#name").val() as string,
                email: formElement.find("#email").val() as string,
                message: formElement.find("#message").val() as string,
                agreeWithTerms: formElement.find("#agreeWithTerms").prop('checked')
            };


            self.mailchimpApiClient.submitHelp(request)
                .then(async (data) => {
                    if (data && data.success) {
                        self.notyf.success("Podatki uspešno poslani!");
                        $("#helpModal").modal("hide");
                        self.loader.hide();
                    }
                    else if (data && data.message) {
                        self.notyf.error(data.message);
                    }
                    else {
                        self.notyf.error("Prišlo je do napake!");

                    }
                })
                .catch(() => {
                    self.notyf.error("Prišlo je do napake");
                });
        });
    }
}
