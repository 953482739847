import { inject, injectable } from "inversify"
import { UserApiClient } from "../clients/users-api-client";
import { CurrentUserInfo } from "../models/current-user-info";
import { Lock } from "../../../../skupno/src/ts/utils/lock";

@injectable()
export class CurrentUserAccessor {
        
    private currentUser: CurrentUserInfo | null = null;
    private lock: Lock = new Lock();

    constructor(@inject("UserApiClient") private userApiClient: UserApiClient
    ) {

    }

    public async getUser(redirectOnUnauthorized: boolean = false, _forceReload: boolean = false): Promise<CurrentUserInfo> {
        const releaser = await this.lock.acquire();
        try {
            this.currentUser = await this.userApiClient.getCurrentUserInfo(redirectOnUnauthorized);


            // if (this.currentUser == null || _forceReload) {
            //    this.currentUser = await this.userApiClient.getCurrentUserInfo(redirectOnUnauthorized);
            //    //await this.translationService.setCurrentLanguage(this.currentUser.languageCode);
            // }
        }
        finally {
            releaser();
        }
        return this.currentUser;
    }

}