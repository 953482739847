import { Container, interfaces } from "inversify";
import { Application } from "../../../skupno/src/ts/application";
import { Authenticator } from "../../../skupno/src/ts/authentication/authenticator";
import { ConfigurationLoader } from "../../../skupno/src/ts/configuration/configuration-loader";
import { Router } from "../../../skupno/src/ts/routing/router";
import { MiddleWareFunction } from "../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../views/main-layout";
import { OpenIdLoginHandler } from "../../../skupno/src/ts/authentication/openid-login-handler";
import { OpenIdIframeApplication } from "../../../skupno/src/ts/authentication/openid-iframe-application";
import { Configuration } from "../../../skupno/src/ts/configuration/configuration";
import { HomePage } from "./pages/home-page";
import { UserApiClient } from "./clients/users-api-client";
import { TranslationsApiClient } from "./clients/translations-api-client";
import { Notyf } from "notyf";
import { TranslationService } from "./translation-service";
import { PageNotFound } from "./pages/page-not-found";
import { SiteMapManager } from "./utilities/sitemap-manager";
import rootNodes from "./sitemap-data";
import { CurrentUserAccessor } from "./utilities/current-user-accessor";
import { Lock } from "../../../skupno/src/ts/utils/lock";
import { FullscreenLoader } from "../views/fullscreen-loader";
import { ChangePasswordModal } from "../views/change-password-modal";
import { HomeApiClient } from "./clients/home-api-client";
import { NewsPage } from "./pages/news-page";
import { AdvicePage } from "./pages/advice-page";
import { NewsSinglePage } from "./pages/news-single-page";
import { ContentPage } from "./pages/content-page";
import { FaqPage } from "./pages/faq-page";
import { TempPage } from "./pages/temp-page";
import { ProfilPage } from "./pages/profil-page";
import { UmbracoApiClient } from "./clients/umbraco-api-client";
import { RegistracijaDrugiKorak } from "./pages/registracija-drugi-korak-page";
import { RegistracijaTretjiKorak } from "./pages/registracija-tretji-korak-page";
import { RidesApiClient } from "./clients/rides-api-client";
import { MailchimpApiClient } from "./clients/mailchimp-api-client";
import { PogojiUporabePage } from "./pages/pogoji-uporabe-page";
import { PolitikaZasebnostiPage } from "./pages/politika-zasebnosti-page";
import { PiskotkiPage } from "./pages/piskotki-page";
import { PogojiSodelovanjaPage } from "./pages/pogoji-sodelovanja-page";
import { AdviceDetailsPage } from "./pages/advice-details-page";
import { IzzivPage } from "./pages/izziv-page";
import { NagradePage } from "./pages/nagrade-page";
import { KakoZacetiPage } from "./pages/kako-zaceti-page";
import { ZaMedijePage } from "./pages/za-medije-page";
import { KotDelodajalecPage } from "./pages/kot-delodajalec-page";
import { KotPodpornikPage } from "./pages/kot-podpornik-page";
import { TeamApiClient } from "./clients/team-api-client";
import { StatistikaIzzivovPage } from "./pages/statistika-izzivov-page";
import { FriendInvitationApiClient } from "./clients/friend-invitation-api-client";
import { CuratorApiClient } from "./clients/curator-api-client";


import { WebPushApiClient } from "./clients/web-push-api-client";
import { NotificationsApiClient } from "./clients/notifications-api-client";

import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';

// You will need a ResizeObserver polyfill for browsers that don't support it! (iOS Safari, Edge, ...)
import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;

/*import { tns } from "tiny-slider/src/tiny-slider";*/
/*@import { owlcarousel } from "owl.carousel";*/

class ProfilApplication extends Application
{    
    
    public constructor() {
        super();
    }

    protected override async configureServices(services: Container): Promise<void> {
        // https://inversify.io/
        // https://github.com/inversify/InversifyJS/blob/master/wiki/value_injection.md
        //services.bind<ApplicationConfiguration>("Katana").toConstantValue(new Katana());
        //services.bind<Katana>("Katana").toDynamicValue((context: interfaces.Context) => { return new Katana(); });
        // a dynamic value can return a promise that will resolve to the value
        //services.bind<Katana>("Katana").toDynamicValue((context: interfaces.Context) => { return Promise.resolve(new Katana()); });

        // Download application configuration
        var configuration: Configuration = await ConfigurationLoader.download('spa.config.json?V=00001');
        services.bind<Configuration>("Configuration").toConstantValue(configuration);

        // Initialise our wrapper class around oidc-client
        var authenticator = new Authenticator(configuration.oauth);
        services.bind<Authenticator>("Authenticator").toConstantValue(authenticator); 

        var translationsApiClient = new TranslationsApiClient(configuration, authenticator);
        services.bind<TranslationsApiClient>("TranslationsApiClient").toConstantValue(translationsApiClient);

        var translationService = new TranslationService(translationsApiClient);
        services.bind<TranslationService>("TranslationService").toConstantValue(translationService);

        var userApiClient = new UserApiClient(configuration, authenticator);
        services.bind<UserApiClient>("UserApiClient").toConstantValue(userApiClient);

        var webPushApiClient = new WebPushApiClient(configuration, authenticator);
        services.bind<WebPushApiClient>("WebPushApiClient").toConstantValue(webPushApiClient);

        services.bind<CurrentUserAccessor>("CurrentUserAccessor").to(CurrentUserAccessor).inSingletonScope();

        var sitemapManager: SiteMapManager = new SiteMapManager(rootNodes);
        services.bind<SiteMapManager>("SiteMapManager").toConstantValue(sitemapManager);

        //configure router
        var middlewareFunctions: MiddleWareFunction[] = new Array<MiddleWareFunction>();
        middlewareFunctions.push(OpenIdLoginHandler.handleLogin);

        //$.fn.select2.defaults.set("dropdownAutoWidth", true);

        //popravek, ker ni fokusa na search fieldih
        //$(document).on('select2:open', (e) => {
        //    const selectId = e.target.id;
        //    $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (_key, value,) {
        //        value.focus();
        //    });
        //});

        // jQuery unobtrusive validation defauls



        $.validator.setDefaults({
            errorClass: "",
            validClass: "",
            highlight: function (element, _errorClass, _validClass) {
                $(element).addClass("is-invalid").removeClass("is-valid");
                if (element.id) {
                    $((element as HTMLInputElement).form!).find("[data-valmsg-for=" + element.id + "]").addClass("invalid-feedback");
                }
            },
            unhighlight: function (element, _errorClass, _validClass) {
                $(element).addClass("is-valid").removeClass("is-invalid");
                if (element.id) {
                  $((element as HTMLInputElement).form!).find("[data-valmsg-for=" + element.id + "]").removeClass("invalid-feedback");
                }
            },
        });
        var notyf = new Notyf({
            dismissible: true,
            duration: 5000,
            ripple: true,
            position: {
                x: 'center',
                y: 'bottom'
            },
            types: [
                {
                    type: 'info',
                    icon: false,
                    className: "bg-primary"
                }
            ]
        });
        services.bind<Notyf>("Notyf").toConstantValue(notyf);

        //routing
        var fullScreenLoader = new FullscreenLoader($("body"));
        services.bind<FullscreenLoader>("FullscreenLoader").toConstantValue(fullScreenLoader);

        $(window).on("router:pagechanging", (_ev) => {
            fullScreenLoader.show();
            window.scrollTo(0, 0);
            $("#header-view").removeClass("hide-menu");
        });
        $(window).on("router:pagechanged", (_ev) => {
            fullScreenLoader.hide();
        });
        $(window).on("router:pageloadingerror", (_ev) => {
            console.log("pageloadingerror:", _ev);
        });

        try {
            await translationService.initialize();
        }
        catch (e) {
            notyf.error({
                dismissible: false,
                position: {
                    x: 'center',
                    y: 'center'
                },
                duration: 0,
                message: "Trenutno poteka vzdrževanje sistema. Prosimo poizkusite čez nekaj minut..."
            })
            throw e;
        }

        //#region home
        middlewareFunctions.push(async () => {
            var page = services.get<HomePage>("HomePage");
            return await page.execute();
        });
        //#endregion namizje
        //#region users

        middlewareFunctions.push(async () => {
            var page = services.get<NewsPage>("NewsPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<IzzivPage>("IzzivPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<NagradePage>("NagradePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<KakoZacetiPage>("KakoZacetiPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<ZaMedijePage>("ZaMedijePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<StatistikaIzzivovPage>("StatistikaIzzivovPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<KotDelodajalecPage>("KotDelodajalecPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<KotPodpornikPage>("KotPodpornikPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<NewsSinglePage>("NewsSinglePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<AdvicePage>("AdvicePage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<AdviceDetailsPage>("AdviceDetailsPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<ContentPage>("ContentPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<FaqPage>("FaqPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<RegistracijaDrugiKorak>("RegistracijaDrugiKorak");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<RegistracijaTretjiKorak>("RegistracijaTretjiKorak");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<TempPage>("TempPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<PogojiUporabePage>("PogojiUporabePage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<PolitikaZasebnostiPage>("PolitikaZasebnostiPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<PiskotkiPage>("PiskotkiPage");
            return await page.execute();
        });
        middlewareFunctions.push(async () => {
            var page = services.get<PogojiSodelovanjaPage>("PogojiSodelovanjaPage");
            return await page.execute();
        });

        middlewareFunctions.push(async () => {
            var page = services.get<ProfilPage>("ProfilPage");
            return await page.execute();
        });

        //#endregion
        
        middlewareFunctions.push(async () => {
            var page = services.get<PageNotFound>("PageNotFound");
            return await page.execute();
        });
        

        //#region navigation
        //middlewareFunctions.push(async () => {
        //    var page = services.get<Navigation>("LoginPage");
        //    return await page.execute();
        //}); 
        //#endregion

        var router = new Router(middlewareFunctions, services);
        services.bind<Router>("Router").toConstantValue(router);


        //#region Strani
        
        //Account
        services.bind<PageNotFound>("PageNotFound").to(PageNotFound).inTransientScope();
        //Home
        services.bind<HomePage>("HomePage").to(HomePage).inTransientScope();
        services.bind<NewsPage>("NewsPage").to(NewsPage).inTransientScope();
        services.bind<NewsSinglePage>("NewsSinglePage").to(NewsSinglePage).inTransientScope();
        services.bind<AdvicePage>("AdvicePage").to(AdvicePage).inTransientScope();
        services.bind<AdviceDetailsPage>("AdviceDetailsPage").to(AdviceDetailsPage).inTransientScope();
        services.bind<ContentPage>("ContentPage").to(ContentPage).inTransientScope();
        services.bind<IzzivPage>("IzzivPage").to(IzzivPage).inTransientScope();
        services.bind<KakoZacetiPage>("KakoZacetiPage").to(KakoZacetiPage).inTransientScope();
        services.bind<ZaMedijePage>("ZaMedijePage").to(ZaMedijePage).inTransientScope();
        services.bind<StatistikaIzzivovPage>("StatistikaIzzivovPage").to(StatistikaIzzivovPage).inTransientScope();
        services.bind<KotDelodajalecPage>("KotDelodajalecPage").to(KotDelodajalecPage).inTransientScope();
        services.bind<KotPodpornikPage>("KotPodpornikPage").to(KotPodpornikPage).inTransientScope();
        services.bind<NagradePage>("NagradePage").to(NagradePage).inTransientScope();
        services.bind<FaqPage>("FaqPage").to(FaqPage).inTransientScope();
        services.bind<TempPage>("TempPage").to(TempPage).inTransientScope();
        services.bind<PogojiUporabePage>("PogojiUporabePage").to(PogojiUporabePage).inTransientScope();
        services.bind<PolitikaZasebnostiPage>("PolitikaZasebnostiPage").to(PolitikaZasebnostiPage).inTransientScope();
        services.bind<PiskotkiPage>("PiskotkiPage").to(PiskotkiPage).inTransientScope();
        services.bind<PogojiSodelovanjaPage>("PogojiSodelovanjaPage").to(PogojiSodelovanjaPage).inTransientScope();
        services.bind<RegistracijaDrugiKorak>("RegistracijaDrugiKorak").to(RegistracijaDrugiKorak).inTransientScope();
        services.bind<RegistracijaTretjiKorak>("RegistracijaTretjiKorak").to(RegistracijaTretjiKorak).inTransientScope();
        services.bind<ProfilPage>("ProfilPage").to(ProfilPage).inTransientScope();
        //#endregion Strani


        //#region Odjemalci
        services.bind<HomeApiClient>("HomeApiClient").to(HomeApiClient).inSingletonScope();
        services.bind<MailchimpApiClient>("MailchimpApiClient").to(MailchimpApiClient).inSingletonScope();
        services.bind<RidesApiClient>("RidesApiClient").to(RidesApiClient).inSingletonScope();
        services.bind<TeamApiClient>("TeamApiClient").to(TeamApiClient).inSingletonScope();
        services.bind<FriendInvitationApiClient>("FriendInvitationApiClient").to(FriendInvitationApiClient).inSingletonScope();
        services.bind<ChangePasswordModal>("ChangePasswordModal").to(ChangePasswordModal).inSingletonScope();
        services.bind<UmbracoApiClient>("UmbracoApiClient").to(UmbracoApiClient).inSingletonScope();
        services.bind<NotificationsApiClient>("NotificationsApiClient").to(NotificationsApiClient).inSingletonScope();
        services.bind<CuratorApiClient>("CuratorApiClient").to(CuratorApiClient).inSingletonScope();
        //#endregion Odjemalci
   

        //let applicationDocument = new MainLayout(window.document, authenticator, router, services.get<UserApiClient>("UserApiClient"));
        var lock = new Lock();
        var mainLayoutSingleton: MainLayout;

        services.bind<interfaces.Provider<MainLayout>>("Provider<MainLayout>")
            .toProvider<MainLayout>((context) => {
                return () => {   

                    if (mainLayoutSingleton) {
                        return Promise.resolve(mainLayoutSingleton);
                    }
                    
                    return new Promise<MainLayout>(async(resolve, reject) => {
                        var releaser = await lock.acquire();
                        try {
                            if (mainLayoutSingleton) {
                                return mainLayoutSingleton;
                            }
                            let mainLayout = new MainLayout(
                                window.document,
                                authenticator,
                                router,
                                context.container.get<CurrentUserAccessor>("CurrentUserAccessor"),
                                context.container.get<Notyf>("Notyf"),
                                context.container.get<FullscreenLoader>("FullscreenLoader"),
                                context.container.get<MailchimpApiClient>("MailchimpApiClient"),
                                context.container.get<UserApiClient>("UserApiClient"),
                                context.container.get<UmbracoApiClient>("UmbracoApiClient"),
                                context.container.get<WebPushApiClient>("WebPushApiClient")
                                //context.container.get<NotificationsApiClient>("NotificationsApiClient")                                                             
                            );

                            await mainLayout.initialize().then(() => {
                                mainLayoutSingleton = mainLayout;
                                resolve(mainLayout);
                            })
                                .catch((e: Error) => {
                                    console.log("mainLayout  error", e);
                                    reject(e);
                                });

                            return mainLayout;
                        }
                        finally {
                            releaser();
                        }
                    });
                };
            });        
        //var slider = tns({
        //    container: '.partnerji',
        //    items: 7,
        //    slideBy: 'page',
        //    autoplay: true
        //});
        //slider;
    }   

}

if (document.readyState !== 'loading') {
    init();
} else {
    document.addEventListener('DOMContentLoaded', init, false);
}

function init() {
    /*
     * The application entry point
     */


    
    if (window.top === window.self) {

        //console.log('empty for now');
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js')
                .then(() => {
                }, (err) => {
                    console.error('Service worker not registered -->', err);
                });
        }
         
        // Run the main app
        const app = new ProfilApplication();
        app.run();

    } else {

        // If our SPA is running on an iframe, handle token renewal responses
        const app = new OpenIdIframeApplication();
        app.run();
    }
}
