import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./advice-view.html";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { HomeApiClient } from "../ts/clients/home-api-client";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";
import { Advice } from "../ts/models/advice";

@injectable()
export class AdviceView {
    private _apiClient: HomeApiClient;
    private _umbracoApiClient: UmbracoApiClient;
    private _translationService: TranslationService;
    private _translations: any;
    private _currentPage: number;
    private _maxPages: number;
    private _advices: Array<Advice>;

    public constructor(desktopApiClient: HomeApiClient,
        umbracoApiClient: UmbracoApiClient,
        translationService: TranslationService,
        private currentUserAccessor: CurrentUserAccessor) {        
        this._apiClient = desktopApiClient;
        this._umbracoApiClient = umbracoApiClient;
        this._translationService = translationService;
        this._translations = null;
        this._currentPage = 0;
        this._maxPages = 0;
        this._advices = new Array<Advice>();
    }

    public async load(): Promise<void> {
        try {
            this._currentPage = this._getCurrentPage();
            var nasvetiResponse = await this._umbracoApiClient.getNasveti(this._currentPage - 1, 12);
            this._advices = nasvetiResponse.Nasveti;
            this._maxPages = nasvetiResponse.MaxPages;
            var response = await this._apiClient.get();
            await this._renderData(response.data, this._advices, nasvetiResponse);                                              

        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private _getCurrentPage(): number {
        var url = window.location.href;
        var urlData = url.split("/");
        var page = Number(urlData[urlData.length - 1]);
        if (isNaN(page) || page == 0) {
            return 1;
        }
        return page;
    }

    private async _renderData(data: string, nasveti: any, nasvetiResponse: any): Promise<void> {
        // Build a view model from the API data
        this._translations = this._translationService.currentTranslations;

        var prviNasvet = null;
        if (nasveti && nasveti.length > 0) {
            prviNasvet = nasveti[0];
            //nasveti.shift();
        }

        var currentUser = await this.currentUserAccessor.getUser();
        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            "imageKuponUrl": "../img/icon-kuponi.png",
            "imageKodaUrl": "../img/koda.jpg",
            "text": data,
            "nasveti": nasveti,
            "prviNasvet": prviNasvet,
            translations: this._translations,
            currentUser: currentUser,
            obstajaVecNasvetov: this._maxPages == this._currentPage ? false : true
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);

        // Render pagination
        this._renderPagination();

        this._initButtons();

        // Set SEO
        $("#meta-description")[0].setAttribute("content", nasvetiResponse.SeoDescription);
        $("#meta-og-title")[0].setAttribute("content", nasvetiResponse.SeoTitle);
        $("#meta-og-description")[0].setAttribute("content", nasvetiResponse.SeoDescription);
        $("#meta-og-image")[0].setAttribute("content", nasvetiResponse.SeoImage);
        $("#meta-twitter-title")[0].setAttribute("content", nasvetiResponse.SeoTitle);
        $("#meta-twitter-description")[0].setAttribute("content", nasvetiResponse.SeoDescription);
        $("#meta-twitter-image")[0].setAttribute("content", nasvetiResponse.SeoImage);
        var currentUrl = window.location.href;
        $("#meta-og-url")[0].setAttribute("content", currentUrl);
        $("#meta-twitter-url")[0].setAttribute("content", currentUrl);
    } 

    private _initButtons(): void {
        var btnLoadMore = $("#btn-load-more");
        btnLoadMore.on("click", async (ev) => {
            await this._loadMoreAdvices(ev);
        });
    }

    private async _loadMoreAdvices(ev: any): Promise<void> {
        ev.preventDefault();
        this._currentPage += 1;

        // Spremenimo url-ja strani
        var newUrl = "#/nasveti/" + this._currentPage.toString();
        history.pushState({}, "", newUrl);

        // Preberemo dodatne nasvete
        var response = await this._umbracoApiClient.getNasveti(this._currentPage - 1, 12);
        var newAdvices = response.Nasveti;
        const templateString = `
                <%#advices%>
                    <div class="col-12 col-md-6 col-xl-3">
                        <div class="advice-block">
                            <a href="#/nasvet/<%{url}%>"><img src="<%{Slika}%>" class="img-fluid ab-image"></a>
                            <div class="advice-content">
                                <h5 class="fw-bold"><a href="#/nasvet/<%{url}%>"><%{Naslov}%></a></h5>
                                <p><%{Povzetek}%></p>
                            </div>
                        </div>
                        <a href="#/nasvet/<%{url}%>" class="btn btn-arrow-short">Preberite več</a>
                    </div>
                <%/advices%>
             `;
        var customTags: any = ['<%', '%>'];
        var html = Mustache.render(
            templateString,
            {
                "advices": newAdvices
            },
            undefined,
            customTags
        );
        $("#nasveti-div").append(html);

        // Na novo renderiramo paginacijo
        this._renderPagination();

        // če ni več novic, skrijemo gumba
        if (this._currentPage == response.MaxPages) {
            $("#btn-load-more").hide();
        }
    }


    private _renderPagination(): void {
        const templateString = `
                <div class="pagination">
                    <a role="button" id="btn-previous-page">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#79B4A9" class="bi bi-chevron-left" viewBox="0 0 16 16" stroke="#79B4A9" stroke-width="1">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                        </svg>
                    </a>
                    <%#pages%>
                        <a 
                            <%#isActive%> class="active" <%/isActive%> 
                            <%#isNumber%> href="#/nasveti/<%{pageNumber}%>" <%/isNumber%>><%{pageNumber}%>
                        </a>
                    <%/pages%>
                    <a role="button" id="btn-next-page">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#79B4A9" class="bi bi-chevron-right" viewBox="0 0 16 16" stroke="#79B4A9" stroke-width="1">
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </a>
                </div>
             `;
        var customTags: any = ['<%', '%>'];
        var pages = this._getPaginationDetails();
        var paginationHtml = Mustache.render(
            templateString,
            {
                "pages": pages
            },
            undefined,
            customTags
        );
        $("#pagination-div").html(paginationHtml);

        var previousPage = $("#btn-previous-page");
        previousPage.on("click", () => {
            if (this._currentPage - 1 == 0) {
                return;
            }
            this._currentPage -= 1;
            window.location.href = "#/nasveti/" + this._currentPage;
        });

        var nextPage = $("#btn-next-page");
        nextPage.on("click", () => {
            if (this._currentPage + 1 > this._maxPages) {
                return;
            }
            this._currentPage += 1;
            window.location.href = "#/nasveti/" + this._currentPage;
        });
    }

    private _getPaginationDetails() {
        var result = new Array<any>();
        // Previous pages
        var page = this._currentPage - 1;
        var previousPages = 2;
        while (previousPages > 0 && page > 0) {
            result.unshift({
                pageNumber: page.toString(),
                isActive: false,
                isNumber: true
            });
            page -= 1;
            previousPages -= 1;
        }
        // First page
        if (page == 1) {
            result.unshift({
                pageNumber: "1",
                isActive: false,
                isNumber: true
            });
        }
        else if (page > 1) {
            result.unshift({
                pageNumber: "...",
                isActive: false,
                isNumber: false
            });
            result.unshift({
                pageNumber: "1",
                isActive: false,
                isNumber: true
            });
        }
        // Current page
        var pageModel = {
            pageNumber: this._currentPage.toString(),
            isActive: true,
            isNumber: true
        };
        result.push(pageModel);
        // Next pages
        var nextPages = 2;
        page = this._currentPage + 1;
        while (nextPages > 0 && page <= this._maxPages) {
            result.push({
                pageNumber: page.toString(),
                isActive: false,
                isNumber: true
            });
            page += 1;
            nextPages -= 1;
        }
        // Last page
        if (this._maxPages - page == 0) {
            result.push({
                pageNumber: this._maxPages.toString(),
                isActive: false,
                isNumber: true
            });
        }
        else if (this._maxPages - page >= 1) {
            result.push({
                pageNumber: "...",
                isActive: false,
                isNumber: false
            });
            result.push({
                pageNumber: this._maxPages.toString(),
                isActive: false,
                isNumber: true
            });
        }
        return result;
    }
}
