import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { CurrentUserAccessor } from "../utilities/current-user-accessor";
import { UmbracoApiClient } from "../clients/umbraco-api-client";
import { RidesApiClient } from "../clients/rides-api-client";
import { NagradeView } from "../../views/nagrade-view";
import { MailchimpApiClient } from "../clients/mailchimp-api-client";
import { FullscreenLoader } from "../../views/fullscreen-loader";

@injectable()
export class NagradePage extends PageBase {
    public name = "NagradePage";
    private _umbracoApiClient: UmbracoApiClient;
    private _ridesApiClient: RidesApiClient;
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _view: NagradeView;
    private _translationService: TranslationService;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;
    private _mailchimpApiClient: MailchimpApiClient;
    private _fullscreenLoader: FullscreenLoader;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,
        @inject("UmbracoApiClient") umbracoApiClient: UmbracoApiClient,
        @inject("RidesApiClient") ridesApiClient: RidesApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("CurrentUserAccessor") currentUserAccessor: CurrentUserAccessor,
        @inject("MailchimpApiClient") mailchimpApiClient: MailchimpApiClient,
        @inject("FullscreenLoader") fullscreenLoader: FullscreenLoader
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._router = router;
        this._translationService = translationService;
        this._notyf = notyf;
        this._umbracoApiClient = umbracoApiClient;
        this._ridesApiClient = ridesApiClient;
        this._mailchimpApiClient = mailchimpApiClient;
        this._fullscreenLoader = fullscreenLoader;
        this._view = new NagradeView(this._umbracoApiClient, this._ridesApiClient, translationService, currentUserAccessor, this._mailchimpApiClient, this._notyf, this._fullscreenLoader);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (url.pathname != "" && !url.pathname.startsWith("/nagrade")) {
            return true;
        }
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            mainLayout.pageTitle = "Nagrade za zagnane";
            mainLayout.htmlTitle = "Nagrade za zagnane";
            //mainLayout.headerUserViewHide();
            //mainLayout.profileSidebarViewShow();
            await this._view.load();
            this._initHeaderActiveTab();
        } catch (e) {
            // Report failures
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }

    private _initHeaderActiveTab() {
        var tabOIzzivu = $(".tab-o-izzivu");
        tabOIzzivu.removeClass("active");

        var tabNagrade = $(".tab-nagrade");
        tabNagrade.addClass("active");

        var tabKakoZaceti = $(".tab-kako-zaceti");
        tabKakoZaceti.removeClass("active");

        var tabNasveti = $(".tab-nasveti");
        tabNasveti.removeClass("active");

        var tabNovice = $(".tab-novice");
        tabNovice.removeClass("active");

        var tabZaMedije = $(".tab-za-medije");
        tabZaMedije.removeClass("active");

        var tabStatistika = $(".tab-statistika");
        tabStatistika.removeClass("active");

        var tabIzvozPodatkov = $(".tab-izvoz-podatkov");
        tabIzvozPodatkov.removeClass("active");
    }
}