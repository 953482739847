import * as mustache from 'mustache';
import { ErrorCodes } from '../../../skupno/src/ts/error-handling/error-codes';
import { ErrorFactory } from '../../../skupno/src/ts/error-handling/error-factory';
import { ErrorFormatter } from '../../../skupno/src/ts/error-handling/error-formatter';
import { ErrorLine } from '../../../skupno/src/ts/error-handling/error-line';
import { UIError } from '../../../skupno/src/ts/error-handling/ui-error';

/*
 * The error view renders error details
 */
export class ErrorView {

    /*
     * Do the initial render to create the HTML, which remains hidden until there is an error
     */
    public load(): void {

        const html =
            `<div class='card border-0'>
                <div class='row'>
                    <div id='errortitle' class='col-10 errorcolor largetext fw-bold text-center'>
                    </div>
                    <div class='col-2 text-end'>
                        <button id='btnClearError' type='button'>x</button>
                    </div>
                </div>
                <div class='row card-body'>
                    <div id='errorform' class='col-12'>
                    </div>
                </div>
            </div>`;

        $('#errorcontainer').html(html)
            .hide();
        $('#btnClearError').on("click", ()=> this.clear());
    }

    /*
     * Do the error rendering given an exception
     */
    public report(exception: any): void {

        // Get the error into an object
        const error = ErrorFactory.getFromException(exception);

        // Do not render if we are just short circuiting page execution to start a login redirect
        if (error.errorCode !== ErrorCodes.loginRequired) {

            // Otherwise render the error fields
            this._renderError(error);
        }
    }

    /*
     * Clear content and hide error details
     */
    public clear(): void {
        $("#errortitle").text('');
        $("#errorform").text('');
        $("#errorcontainer").hide();
    }

    /*
     * Render the error to the UI
     */
    private _renderError(error: UIError): void {

        // Clear content and make the form visible
        $("#errortitle").text('');
        $("#errorform").text('');
        $("#errorcontainer").show();

        // Render the title
        $("#errortitle").text('Problem Encountered');

        // Render the error fields
        const errorHtml =
            this._getLinesHtml(ErrorFormatter.getErrorLines(error)) +
            this._getStackHtml(ErrorFormatter.getErrorStack(error));
        $("#errorform").html(errorHtml);
    }

    /*
     * Get the HTML for the error lines
     */
    private _getLinesHtml(errorLines: ErrorLine[]): string {

        const htmlTemplate =
            `{{#lines}}
                <div class='row'>
                    <div class='col-4'>
                        {{label}}
                    </div>
                    <div class='col-8 valuecolor fw-bold'>
                        {{value}}
                    </div>
                </div>
            {{/lines}}`;

        return mustache.render(htmlTemplate, { lines: errorLines });
    }

    /*
     * Get the HTML for the error stack trace
     */
    private _getStackHtml(stackLine: ErrorLine | null): string {

        if (!stackLine) {
            return '';
        }

        const htmlTemplate =
            `<div class='row' />
                <div class='col-4'>
                    &nbsp;
                </div>
                <div class='col-8'>
                    &nbsp;
                </div>
            </div>
            <div class='row' />
                 <div class='col-4'>
                     {{label}}
                 </div>
                 <div class='col-8 small'>
                     {{value}}
                 </div>
             </div>`;

        return mustache.render(htmlTemplate, stackLine);
    }
}