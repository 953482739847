import { injectable } from "inversify";
import * as Mustache from "mustache";
import template from "./footer-view.html";
import helpModal from "./help-modal.html";
import  "bootstrap";
import { MailchimpApiClient } from "../ts/clients/mailchimp-api-client";
import { Notyf } from "notyf";
import { FullscreenLoader } from "./fullscreen-loader";
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { HelpModel } from "../ts/models/help-model";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";

@injectable()
export class FooterView {

    constructor(private mailchimpApiClient: MailchimpApiClient,
        private umbracoApiClient: UmbracoApiClient,
        private notyf: Notyf,
        private loader: FullscreenLoader,
        private rootElement: HTMLElement) { }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try
        {
            var self = this;
            var partnerji = await this.umbracoApiClient.getPartnerji();
            var podporniki = await this.umbracoApiClient.getPodporniki();

            // Render new content
            await this._renderData(partnerji, podporniki);   
            
            new Splide('#splide-podporniki', {
                perPage: 6,
                padding: '0 5rem 0 0 ',
                drag: 'free',
                //drag: false,
                //type: 'loop',
                snap: true,
                //focus: 'center',
                //autoScroll: false,
                gap: '3em',
                height: '85px',
                arrows: false,
                pagination: false,
                //autoScroll: {
                //    speed: .5,
                //},
                breakpoints: {
                    992: {
                        perPage: 3,
                        type: 'loop',
                        autoScroll: {
                            speed: .5,
                        },
                    },
                }
            }).mount({ AutoScroll });

            new Splide('#splide-partnerji', {
                perPage: 6,
                padding: '0 5rem 0 0 ',
                drag: 'free',
                //drag: false,
                type: 'loop',
                snap: true,
                //focus: 'center',
                //autoScroll: true,
                gap: '3em',
                height: '40px',
                arrows: false,
                pagination: false,
                autoScroll: {
                    speed: .5,
                },
                breakpoints: {
                    992: {
                        perPage: 3,
                        //drag: false
                    },
                }
            }).mount({ AutoScroll });

            $(".helpBtn").on("click", function () {
                self._initHelp();
            });
        }
        catch (e) {

            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }    

    private async _renderData(partnerji: any, podporniki: any): Promise<void> {        
        // Build a view model from the API data
        const viewModel = {
            "partnerji": partnerji,
            "podporniki": podporniki,
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);
        $('#footer-view').html(html); 

        var formElement = $("#newsletterForm");
        $.validator.unobtrusive.parse(formElement);
        formElement.validate();

        formElement.on("submit", (ev) => {
            ev.preventDefault();
            if (!formElement.valid()) {
                return;
            }
            this.loader.show();
            var email = formElement.find("[name=email]").val() as string;
            this.mailchimpApiClient.mailchimpSubscribe(email)
                .then(() => {
                    formElement.find("[name=email]").val('');
                    formElement.find("[name=agreeWithTerms]").prop("checked", false);
                    this.notyf.success("Uspešno ste se prijavili na e novice");
                    this.loader.hide();
                })
                .catch(() => {
                    this.notyf.error("Prišlo je do napake");
                    this.loader.hide();
                });
        });
    }

    private async _initHelp(): Promise<void> {
        var self = this;

        const helpViewModel = {} as any;

        const modalHtml = Mustache.render(helpModal, helpViewModel);
        $('#helpWrap').html(modalHtml);

        $('#helpModal').modal("show");

        var formElement = $("#helpForm");
        $.validator.unobtrusive.parse(formElement);
        formElement.validate();

        formElement.on("submit", function (ev) {
            ev.preventDefault();
            if (!formElement.valid()) {
                return;
            }

            var request: HelpModel = {
                name: formElement.find("#name").val() as string,
                email: formElement.find("#email").val() as string,
                message: formElement.find("#message").val() as string,
                agreeWithTerms: formElement.find("#agreeWithTerms").prop('checked')
            };


            self.mailchimpApiClient.submitHelp(request)
                .then(async (data) => {
                    if (data && data.success) {
                        self.notyf.success("Podatki uspešno poslani!");
                        $("#helpModal").modal("hide");
                        self.loader.hide();
                    }
                    else if (data && data.message) {
                        self.notyf.error(data.message);
                    }
                    else {
                        self.notyf.error("Prišlo je do napake!");

                    }
                })
                .catch(() => {
                    self.notyf.error("Prišlo je do napake");
                });
        });
    }

    public set visible(isVisible: boolean) {
        $(this.rootElement).toggle(isVisible);
    }
}