import { Container } from "inversify";
import { Application } from "../application";
import { ConfigurationLoader } from "../configuration/configuration-loader";
import { MiddleWareFunction } from "../routing/page-base";
import { Router } from "../routing/router";
import { Authenticator } from "./authenticator";
import { OpenIdLogoutHandler } from "./openid-logout-handler";

export class OpenIdIframeApplication extends Application
{    
    public constructor() {
        super();
    }

    protected override async configureServices(services: Container): Promise<void> {
        // Download application configuration
        var configuration = await ConfigurationLoader.download('spa.config.json');
        // Initialise our wrapper class around oidc-client
        var authenticator = new Authenticator(configuration.oauth);
        services.bind<Authenticator>("Authenticator").toConstantValue(authenticator);

        //configure router
        var middlewareFunctions: MiddleWareFunction[] = new Array<MiddleWareFunction>();
        middlewareFunctions.push(OpenIdLogoutHandler.handleLogout);

        // Our simple router passes the API Client instance between views
        var router = new Router(middlewareFunctions, services);        
        services.bind<Router>("Router").toConstantValue(router);
    }
    
}