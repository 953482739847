import { inject } from "inversify";
import * as Mustache from "mustache";
import { Notyf } from "notyf";
import { UserApiClient } from "../clients/users-api-client";
import { RegionEmployerRequest } from "../models/region-employer-model-request";
import regionEmployerModal from "./../../views/region-employer-modal.html";
import { CookieManager } from "./cookie-manager";

export class RegionEmployerManager {

    private _userApiClient: UserApiClient;
    private _cookieManager: CookieManager;
    private _notyf: Notyf;

    public constructor(@inject("UserApiClient") userApiClient: UserApiClient,
        @inject("Notyf") notyf: Notyf
    ) {
        this._userApiClient = userApiClient;
        this._cookieManager = new CookieManager();
        this._notyf = notyf;        
    }

    public async _updateRegionAndEmployerModal() {
        var showModalModel = await this._userApiClient.showRegionEmployerModal();
        var cookieSaved = this._cookieManager.cookieZeShranjen(".RegionEmployerUpdate");
        if (showModalModel.showModal && !cookieSaved) {
            const regionEmployerModel = {
                region: showModalModel.region == null ? "" : showModalModel.region.name,
                company: showModalModel.employer == null ? "" : showModalModel.employer.imeZavezanca
            } as any;

            const html = Mustache.render(regionEmployerModal, regionEmployerModel);
            $("#regionEmployerModalWrap").html(html);
            if (showModalModel.region == null && showModalModel.employer == null) {
                ($("#btn-region-employer-submit")[0] as HTMLInputElement).disabled = true;
            }

            await this._initSearchFields();

            var self = this;

            var btnUpdateLater = $("#btn-region-employer-later");
            btnUpdateLater.on("click", function () {
                self._cookieManager.setCookieForRegionEmployer();
                $("#regionEmployerModal").modal("hide");
                self._notyf.success("Uspešno shranjeno.");
            });

            var btnUpdateSubmit = $("#btn-region-employer-submit");
            btnUpdateSubmit.on("click", function (ev) {
                ev.preventDefault();
                var request: RegionEmployerRequest = {
                    regionId: $("#region-search-modal").val() as string,
                    employerId: $("#employer-search-modal").val() as string,
                };
                self._userApiClient.updateRegionEmployer(request)
                    .then(async (data) => {
                        if (data && data.success) {
                            self._notyf.success(data.message);
                            $("#regionEmployerModal").modal("hide");
                        }
                        else if (data && data.message) {
                            self._notyf.error(data.message);
                        }
                        else {
                            self._notyf.error("Prišlo je do napake!");
                        }
                    })
                    .catch(() => {
                        self._notyf.error("Prišlo je do napake");
                    });
            });
            $("#regionEmployerModal").modal("show");         
        }
    }

    private async _initSearchFields() {
        require('select2');

        let NazivDelodajalca = $(".employer-search-field-modal");
        NazivDelodajalca.select2({
            ajax: (await this._userApiClient.companySearchOptions((settings) => {
                settings.url += "&" + jQuery.param({
                    search: $('.employer-search-field-modal').data("select2").dropdown.$search.val()
                }, false);
                settings.error = function () { };
            })) as any,
            placeholder: "",
            minimumInputLength: 3,
            allowClear: false,
            dropdownParent: $("#employer-search-wrap-modal"),
            language: {
                inputTooShort: function () {
                    return 'Za iskanje vnesite vsaj tri znake.';
                },
                noResults: function () {
                    return 'Ni rezultatov iskanja.'
                },
                searching: function () {
                    return 'Iskanje...'
                }
            }
        });
        var izbranNazivDelodajalca = $("[name=employer-search-hidden-modal]").val() as string;
        NazivDelodajalca.append(new Option(izbranNazivDelodajalca, izbranNazivDelodajalca)).val(izbranNazivDelodajalca).trigger("change");
        NazivDelodajalca.on('select2:select', function () {

        }).on('select2:select', function () {
            $(this).blur();
        }).on('select2:open', function (e) {
            (document.querySelector(`[aria-controls="select2-${e.target.id}-results"]`) as HTMLElement).focus();
        });

        $("#employer-search-modal").on("change", () => {
            var employerId = $("#employer-search-modal").val() as string;
            if (employerId != "") {
                ($("#btn-region-employer-submit")[0] as HTMLInputElement).disabled = false;
            }
        });

        let Regija = $(".region-search-field-modal");
        Regija.select2({
            ajax: (await this._userApiClient.regionSearchOptions((settings) => {
                settings.url += "&" + jQuery.param({
                    search: $('.region-search-field-modal').data("select2").dropdown.$search.val()
                }, false);
                settings.error = function () { };
            })) as any,
            placeholder: "",
            minimumInputLength: 2,
            allowClear: false,
            dropdownParent: $("#region-search-wrap-modal"),
            language: {
                inputTooShort: function () {
                    return 'Za iskanje vnesite vsaj dva znaka.';
                },
                noResults: function () {
                    return 'Ni rezultatov iskanja.'
                },
                searching: function () {
                    return 'Iskanje...'
                }
            }
        });
        var izbranaRegija = $("[name=region-search-hidden-modal]").val() as string;
        Regija.append(new Option(izbranaRegija, izbranaRegija)).val(izbranaRegija).trigger("change");
        Regija.on('select2:select', function () {

        }).on('select2:select', function () {
            $(this).blur();
        }).on('select2:open', function (e) {
            (document.querySelector(`[aria-controls="select2-${e.target.id}-results"]`) as HTMLElement).focus();
        });

        $("#region-search-modal").on("change", () => {
            var regionId = $("#region-search-modal").val() as string;
            if (regionId != "") {
                ($("#btn-region-employer-submit")[0] as HTMLInputElement).disabled = false;
            }
        });
    }


}
