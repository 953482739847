import { Container, inject, injectable } from "inversify";
import { MiddleWareFunction } from "./page-base";
import "reflect-metadata";

/*
 * 
 */
@injectable()
export class Router {
    public name = "Router";
    private _virtualUrl: URL;
    private _middlewares: MiddleWareFunction[];
    private _serviceProvider: Container;

    public constructor(@inject("MiddleWareFunctions") middlewares: MiddleWareFunction[],
        @inject("Container") serviceProvider: Container) {
        this._onHashChange = this._onHashChange.bind(this);
        this._middlewares = middlewares;
        this._serviceProvider = serviceProvider;
        this._virtualUrl = this._createVirtualUrl();

        $(window).on("hashchange", this._onHashChange);
    }

    /*
     * se zažene ob vsaki spremembi navigacije
     */
    public async execute() {
        $(window).trigger("router:pagechanging");
        this._virtualUrl = this._createVirtualUrl();

        try {
            for (var i = 0; i < this._middlewares.length; i++) {
                var middleware = this._middlewares[i];
                var stop = await middleware(this._serviceProvider) === false;
                if (stop) {
                    break;
                }
            }
        }
        catch (e: any) {
            $(window).trigger("router:pageloadingerror", { exception: e, virtualUrl: this._virtualUrl });
        }
        $(window).trigger("router:pagechanged");
    }
   

    public async refresh() {
        await this.execute();
    }

    private _onHashChange() {
        this.execute();
    }

    /*
     * Get hash fragments into a dictionary
     */
    private _createVirtualUrl(): URL {
        var newUrl = new URL('http://nidomene/');
        if (location.hash.startsWith('#')) {
            const data = new URL('http://nidomene/?' + location.hash.substring(1))
            var queryString = Array.from(data.searchParams);
            
            for (const [key, value] of queryString) {
                if (key.startsWith("/")) {
                    newUrl.pathname = key;
                }
                else {
                    newUrl.searchParams.set(key, value);
                }
            }
        }
        return newUrl;
    }

    public getVirtualUrl(): URL {
        return this._virtualUrl;
    }
}