import * as Mustache from "mustache";
import template from "./../../views/notifications-modal.html";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";
import { UserApiClient } from "../clients/users-api-client";
import { WebPushApiClient } from "../clients/web-push-api-client";

export class NotificationsManager {

    public constructor() { }


    public async initNotificationsModal(userApiClient: UserApiClient, webPushApiClient:WebPushApiClient) {
        const viewModel = {};
        const htmlTemplate = template;
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#notificationModalWrap').html(html);
        let os: string = "";
        if ("navigator" in window) {
            const platform = (navigator.userAgent || navigator.platform)?.toLowerCase();

            if (platform.indexOf('windows nt') != -1) {
                os = 'windows';
            }
            if (platform.indexOf('x11') != -1) {
                os = 'chromebook';
            }
            if (platform.indexOf('Macintosh') != -1) {
                os = 'macintosh';
            }
        }


        

        //$("#notifModal").on("shown.bs.modal", () => {
        //    $("#notifModal")[0].style.display = "flex";
        //});

        const firebaseConfig = {      //produkcijski
            apiKey: "AIzaSyDaFTg3Pe_eLLLnHlRtpjonOj6H82GQcjk",
            authDomain: "web-push-notifications-32905.firebaseapp.com",
            projectId: "web-push-notifications-32905",
            storageBucket: "web-push-notifications-32905.appspot.com",
            messagingSenderId: "152298474225",
            appId: "1:152298474225:web:4d0147cc8ec91d0a707750",
            measurementId: "G-BYZNS3JYEQ"
        };
        //const firebaseConfig = {   //testni
        //    apiKey: "AIzaSyC4x31jrC2L6s4nD9s-3MssVBPa1u08pg8",
        //    authDomain: "web-push-test-293c9.firebaseapp.com",
        //    projectId: "web-push-test-293c9",
        //    storageBucket: "web-push-test-293c9.appspot.com",
        //    messagingSenderId: "242287092443",
        //    appId: "1:242287092443:web:2087b6f5d71dd7f45a62fe",
        //    measurementId: "G-QRGRBP9DNL"
        //};
        const firebaseApp = initializeApp(firebaseConfig);
        const messaging = getMessaging(firebaseApp);
        let userToken: string;

        function registerSW() {  
                onMessage(messaging, (payload: any) => {
                    // console.log('Notification received.');
                    const notificationTitle = payload.notification.title;
                    const notificationOptions = {
                        body: payload.notification.body,
                        icon: payload.notification.icon,
                    };
                    // console.log(payload);
                    if (!("Notification" in window)) {
                        console.log("This browser does not support system notifications");
                    }
                    // Let's check whether notification permissions have already been granted
                    else if (Notification.permission === "granted") {
                        // If it's okay let's create a notification
                        var notification = new Notification(notificationTitle, notificationOptions);
                        notification.onclick = function (event) {
                            event.preventDefault(); // prevent the browser from focusing the Notification's tab
                            window.open(payload.data.redirectUrl, '_blank');
                            notification.close();
                        }
                    }
                });
                if ('serviceWorker' in navigator) {
                    //navigator.serviceWorker.getRegistration('firebase-messaging-sw.js')
                    navigator.serviceWorker.register('firebase-messaging-sw.js')
                        .then(function (sw) {

                            //BOheWmTAofMMyfsTxkkQ_fieHWDbE55UtTChAzfFga4vgtZKeIQGb5QCFPvXc7BA-Y9CsmSvW5324FjaJNxo6c0     test
                            //BC_CQwKmlrRGV9KcZb6EJbkBGH3OzdHcA320ZB7c1VgA3qfs3ZWXQOr2s4uMCcsH6Ri1SMYZq-Spl4LVk6nIO64     prod
                            getToken(messaging, { vapidKey: 'BC_CQwKmlrRGV9KcZb6EJbkBGH3OzdHcA320ZB7c1VgA3qfs3ZWXQOr2s4uMCcsH6Ri1SMYZq-Spl4LVk6nIO64', serviceWorkerRegistration: sw }).then(async (currentToken) => {
                                if (currentToken) {
                                    userToken = currentToken;
                                    webPushApiClient.refreshToken(currentToken);
                                } else {
                                    console.log('No registration token available. Request permission to generate one.');
                                }
                                
                            }).catch((err) => {
                                console.log('An error occurred while retrieving token. ', err);
                            });
                        });
                }
                if ('permissions' in navigator) {
                    navigator.permissions.query({ name: 'notifications' }).then(function (notificationPerm) {
                        notificationPerm.onchange = function () {
                            if (notificationPerm.state === 'denied') {
                                deleteToken(messaging);
                                webPushApiClient.deleteToken(userToken);
                            };
                        };
                    });
                }
                
            }

            if (await userApiClient.getCurrentUserInfo() != null && !window.location.hash.startsWith("#/registracija") && os != "") {
                if (Notification.permission === "granted") {
                    registerSW();
                }
                else if (Notification.permission === "default") {

                    $('#notifModal').modal('show');
                    Notification.requestPermission().then((permission) => {
                        $('#notifModal').modal('hide');
                        if (permission === 'granted') {
                            registerSW();
                        }
                    });
                }
            }
        
        //end notifications
    }
}