import * as Mustache from "mustache";
import template from "./../../views/cookie-modal.html";

export class CookieManager {

    public constructor() {}

    public cookieZeShranjen(cname: string): boolean {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                var cookie = c.substring(name.length, c.length);
                if (cookie.length > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    public setCookie(modalClosed: boolean) {
        var nujnoPotrebniPiskotki = true;
        var analiticniPiskotki = true;
        var trzniPiskotki = true;
        // Če uporabnik ni zaprel modalca preverimo kaj je izbral
        if (!modalClosed) {
            // vedno dovolimo nujno potrebne piškotke in tega ne rabimo prebrat
            analiticniPiskotki = ($("#analiticni-piskotki")[0] as HTMLInputElement).checked;
            trzniPiskotki = ($("#trzni-piskotki")[0] as HTMLInputElement).checked;
        }
        this.writeCookie(nujnoPotrebniPiskotki, analiticniPiskotki, trzniPiskotki);
        $("#cookieModal").modal("hide");
    }

    public writeCookie(nujnoPotrebniPiskotki: boolean, analiticniPiskotki: boolean, trzniPiskotki: boolean) {
        const date = new Date();
        var days = 30;
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toUTCString();
        var name = `.IzbraniPiskotki`;
        var value = `nujnoPotrebniPiskotki=${nujnoPotrebniPiskotki}|analiticniPiskotki=${analiticniPiskotki}|trzniPiskotki=${trzniPiskotki};`
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    public initCookieModal() {
        const viewModel = {};
        const htmlTemplate = template;
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#cookieModalWrap').html(html);
        var btnCloseModal = $(".btn-cookie-close");
        for (let i = 0; i < btnCloseModal.length; i++) {
            btnCloseModal[i].addEventListener("click", () => {
                this.setCookie(true);
            });
        }
        var btnShraniNastavitve = $("#btn-shrani-nastavitve");
        btnShraniNastavitve.on("click", () => {
            this.setCookie(false);
        });

        var analiticniPiskotkiCheckBox = $("#analiticni-piskotki");
        analiticniPiskotkiCheckBox.on("click", () => {
            if ((analiticniPiskotkiCheckBox[0] as HTMLInputElement).checked) {
                $("#analiticni-piskotki-label-yes").show();
                $("#analiticni-piskotki-label-no").hide();
            }
            else {
                $("#analiticni-piskotki-label-yes").hide();
                $("#analiticni-piskotki-label-no").show();
            }
        });

        var trzniPiskotkiCheckBox = $("#trzni-piskotki");
        trzniPiskotkiCheckBox.on("click", () => {
            if ((trzniPiskotkiCheckBox[0] as HTMLInputElement).checked) {
                $("#trzni-piskotki-label-yes").show();
                $("#trzni-piskotki-label-no").hide();
            }
            else {
                $("#trzni-piskotki-label-yes").hide();
                $("#trzni-piskotki-label-no").show();
            }
        });

        $("#cookieModal").on("shown.bs.modal", () => {
            $("#cookieModal")[0].style.display = "flex";
        });
    }

    public setCookieForWebNotifications() {
        const date = new Date();
        var days = 1000;
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toUTCString();
        var name = `.AllowWebNotifications`;
        var value = `false;` // vedno nastavimo da je false, ker uporabnik ni potrdil
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    public setCookieForRegionEmployer() {
        const date = new Date();
        var days = 7;
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toUTCString();
        var name = `.RegionEmployerUpdate`;
        var value = `false;` // vedno nastavimo da je false, ker uporabnik ni shranil
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }
}
