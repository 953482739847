import { Container, injectable } from "inversify";

@injectable()
export abstract class PageBase {

	public constructor() {
	}

	/*
	 * Zažene stran in nastavi potrebne stvari.
	 * */
	public abstract execute(): Promise<boolean>;

}

export type MiddleWareFunction = (serviceProvider: Container) => Promise<boolean>;