import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { CurrentUserInfo } from "../models/current-user-info";
import { ListResponse } from "../models/list-response";
import { RegistracijaDrugiKorakModel } from "../models/registracija-drugi-korak-model";
import { ProfileSaveModel } from "../models/profile-basic-data-save-model";
import { RegistracijaTretjiKorakModel } from "../models/registracija-tretji-korak-model";
import { User } from "../models/user"
import { UserDetailsVlogeResponse } from "../models/user-details-vloge-response";
import { UserListInitResponse } from "../models/user-list-init-response";
import { BikerInfoFormRequest } from "../models/biker-info-form-request";
import { BikerInfoFormResponse } from "../models/biker-info-form-response";
import { RegionEmployerRequest } from "../models/region-employer-model-request";
import { ExistingBikerInfoFormRequest } from "../models/biker-info-existimg-form-request";

@injectable()
export class UserApiClient extends ApiClientBase {
   
    public name = "UserApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator")  authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async updateProfile(profileSaveModel: ProfileSaveModel): Promise<any> {
        return await this._callApi('/User/UpdateProfile', 'POST', JSON.stringify(profileSaveModel), true, "") as any;
    }
    

    public async updateProfileFormData(profileSaveModel: FormData): Promise<any> {
        return await this._callApiWithFormData('/User/UpdateProfile', 'POST', profileSaveModel, true) as any;
    }

    public async getListInit(page?: number, limit?: number, searchTerm?: string, locationId?: string): Promise<UserListInitResponse> {
        const data = {
            "page": page,
            "limit": limit,
            "search": searchTerm,
            "locationId": locationId
        };
        return await this._callApi('/user/list-init', 'GET', data) as UserListInitResponse;
    }
    public async getList(page?: number, limit?: number, searchTerm?: string, locationId?: string): Promise<ListResponse<User>> {
        const data = {
            "page": page,
            "limit": limit,
            "search": searchTerm,
            "locationId": locationId
        };
        return await this._callApi('/user/list', 'GET', data) as ListResponse<User>;
    }
    public async getCurrentUserInfo(redirectOnUnauthorized: boolean = false): Promise<CurrentUserInfo> {
        const data = {
        };

    
        return await this._callApi('/user/current-user', 'GET', data, redirectOnUnauthorized) as CurrentUserInfo;
    }

    public async userDetails(userId: number): Promise<CurrentUserInfo> {
        const data = {
        };
        return await this._callApi('/user/user-details/' + userId, 'GET', data, true) as CurrentUserInfo;
    }

    public async userDetailsVloge(userId: number): Promise<UserDetailsVlogeResponse> {
        const data = {
        };
        return await this._callApi('/user/user-details/' + userId + '/vloge', 'GET', data, true) as UserDetailsVlogeResponse;
    }
    public async isEmailConfiremd(): Promise<boolean> {
        return await this._callApi('/user/user-details/isEmailConfirmed', 'GET', true) as boolean;
    }

    public resendVerification() {      
        return this._callApi('/user/resend', 'GET', true);
    }     
    
    public async userDetailsVlogeShrani(userId: number, izbraneVloge: Array<number>): Promise<UserDetailsVlogeResponse> {
        const data = izbraneVloge;
        return await this._callApi('/user/user-details/' + userId + '/vloge', 'POST', JSON.stringify(data), true) as UserDetailsVlogeResponse;
    }

    public async validate(hash: string): Promise<any> {
        const data = {
        };
        return await this._callApi('/user/validate/' + hash, 'GET', data, true) as any;
    } 

    public async profileChangePassword(newPassword:string): Promise<void> {
        return await this._callApi('/user/profile/password/change', 'POST', JSON.stringify(newPassword), true, "");
    }
    public async profileChangeEmail(newEmail: string): Promise<void> {
        return await this._callApi('/user/profile/email/change', 'POST', JSON.stringify(newEmail), true, "");
    } 
   
    public async passwordReset(username: string): Promise<void> {
        return await this._callApi('/user/password/reset', 'POST', JSON.stringify(username), false, "");
    }
    public async passwordResetCheck(hash: string): Promise<void> {
        return await this._callApi('/user/password/reset/check/' + hash, 'GET', null, false, "");
    }
    public async passwordResetChange(hash: string, newPassword: string): Promise<void> {
        return await this._callApi('/user/password/reset/' + hash, 'POST', JSON.stringify(newPassword), false, "");
    } 

    public async registracijaDrugiKorakShrani(data: RegistracijaDrugiKorakModel): Promise<boolean> {
        return await this._callApi('/user/registracija/drugi-korak', 'POST', JSON.stringify(data), false, "");
    }

    public async registracijaTretjiKorakShrani(data: RegistracijaTretjiKorakModel): Promise<boolean> {
        return await this._callApi('/user/registracija/tretji-korak', 'POST', JSON.stringify(data), false, "");
    }

    public async getBikerInfoForm(): Promise<BikerInfoFormResponse | null> {
        return await this._callApi('/user/get-biker-info-form', 'GET', null, false, "") as BikerInfoFormResponse | null;
    }

    public async showBikerInfoForm(): Promise<boolean | null> {
        return await this._callApi('/user/show-biker-info-form', 'GET', null, false, "") as boolean;
    } 

    public async showExistinhBikerInfoForm(): Promise<boolean | null> {
        return await this._callApi('/user/show-existing-biker-info-form', 'GET', null, false, "") as boolean;
    } 


    public async addBikerInfoForm(data: BikerInfoFormRequest): Promise<boolean> {
        return await this._callApi('/user/add-biker-info-form', 'POST', JSON.stringify(data), false, "");
    }


    public async addExistingBikerInfoForm(data: ExistingBikerInfoFormRequest): Promise<boolean> {
        return await this._callApi('/user/existing-biker-info-form', 'POST', JSON.stringify(data), false, "");
    }

    public async getAllowWebNotifications(): Promise<any> {
        return await this._callApi('/user/get-allow-web-notifications', 'GET', null, true, "") as any;
    }

    public async setAllowWebNotifications(allow: boolean): Promise<any> {
        return await this._callApi('/user/set-allow-web-notifications/' + allow, 'POST', null, true, "") as any;
    }

    public async companySearchOptions(prepareRequest: (request: JQueryAjaxSettings) => void): Promise<JQuery.AjaxSettings> {
        return await this._createAjaxOptionsWithToken('/user/company/search', 'GET', "", false, "json", undefined, undefined, prepareRequest);
    }

    public async regionSearchOptions(prepareRequest: (request: JQueryAjaxSettings) => void): Promise<JQuery.AjaxSettings> {
        return await this._createAjaxOptionsWithToken('/user/region/search', 'GET', "", false, "json", undefined, undefined, prepareRequest);
    }

    public async showRegionEmployerModal(): Promise<any> {
        return await this._callApi('/user/show-region-employer-modal', 'GET', null, false, "") as any;
    }

    public async updateRegionEmployer(data: RegionEmployerRequest): Promise<any> {
        return await this._callApi('/user/update-region-employer', 'POST', JSON.stringify(data), true, "") as any;
    }
    
    public async updateShowExistignForm(): Promise<any> {
        return await this._callApi('/user/update-user-show-form', 'POST', true) as any;
    }

  

} 