import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";

@injectable()
export class FriendInvitationApiClient extends ApiClientBase {
    public name = "FriendInvitationApiClient";
    public constructor(

        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async sendFriendInvitation(email: string): Promise<any> {
        return await this._callApi('/FriendInvitation/SendInvitation', 'POST', JSON.stringify(email), true, "") as any;
    }
}
