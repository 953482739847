import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./registracija-tretji-korak-view.html";
import { HomeApiClient } from "../ts/clients/home-api-client";
import { RegistracijaTretjiKorakModel } from "../ts/models/registracija-tretji-korak-model";
import { UserApiClient } from "../ts/clients/users-api-client";
import { Notyf } from "notyf";
import "jquery-validation-unobtrusive";
import "select2";

@injectable()
export class RegistracijaTretjiView {
    private _homeApiClient: HomeApiClient;
    private _translationService: TranslationService;
    private _translations: any;
    private _formElement: JQuery<HTMLElement> | null;
    private _userApiClient: UserApiClient;

    public constructor(homeApiClient: HomeApiClient,
        userApiClient: UserApiClient,
        translationService: TranslationService,
        private _notyf: Notyf) {
        this._homeApiClient = homeApiClient;
        this._userApiClient = userApiClient;
        this._translationService = translationService;
        this._translations = null;
        this._formElement = null;
    }

    public async load(): Promise<void> {
        try {
            var response = await this._homeApiClient.get();
            await this._renderData(response.data);
            await this._initForm();

        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _renderData(data: string): Promise<void> {

        // Build a view model from the API data
        this._translations = this._translationService.currentTranslations;

        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            "imageKuponUrl": "../img/icon-kuponi.png",
            "imageKodaUrl": "../img/koda.jpg",
            "text": data,
            translations: this._translations
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);

    }

    private async _initForm() {
        this._dodajValidacijskeFunkcije();
        this._formElement = $(("#registracija-form"));
        $.validator.unobtrusive.parse(this._formElement);
        this._formElement.validate();
        
        var btnSubmit = $("#btn-submit");
        if (!btnSubmit) return;
        btnSubmit.on("click", (ev: Event) => this._onBtnSubmitClick(ev, this._formElement));

        await this._initSearchFields();
    }

    private async _initSearchFields() {
        require('select2');
        let NazivDelodajalca = $(".employer-search-field");
        NazivDelodajalca.select2({
            ajax: (await this._userApiClient.companySearchOptions((settings) => {
                settings.url += "&" + jQuery.param({
                    search: $('.employer-search-field').data("select2").dropdown.$search.val()
                }, false);
                settings.error = function () { };
            })) as any,
            placeholder: "",
            minimumInputLength: 3,
            allowClear: false,
            language: {
                inputTooShort: function () {
                    return 'Za iskanje vnesite vsaj tri znake.';
                },
                noResults: function () {
                    return 'Ni rezultatov iskanja.'
                },
                searching: function () {
                    return 'Iskanje...'
                }
            }
        });
        var izbranNazivDelodajalca = $("[name=employer-search-hidden]").val() as string;
        NazivDelodajalca.append(new Option(izbranNazivDelodajalca, izbranNazivDelodajalca)).val(izbranNazivDelodajalca).trigger("change");
        NazivDelodajalca.on('select2:select', function () {

        }).on('select2:select', function () {
            $(this).blur();
        }).on('select2:open', function (e) {
            (document.querySelector(`[aria-controls="select2-${e.target.id}-results"]`) as HTMLElement).focus();
        });

        let Regija = $(".region-search-field");
        Regija.select2({
            ajax: (await this._userApiClient.regionSearchOptions((settings) => {
                settings.url += "&" + jQuery.param({
                    search: $('.region-search-field').data("select2").dropdown.$search.val()
                }, false);
                settings.error = function () { };
            })) as any,
            placeholder: "",
            minimumInputLength: 2,
            allowClear: false,
            language: {
                inputTooShort: function () {
                    return 'Za iskanje vnesite vsaj dva znaka.';
                },
                noResults: function () {
                    return 'Ni rezultatov iskanja.'
                },
                searching: function () {
                    return 'Iskanje...'
                }
            }
        });
        var izbranaRegija = $("[name=region-search-hidden]").val() as string;
        Regija.append(new Option(izbranaRegija, izbranaRegija)).val(izbranaRegija).trigger("change");
        Regija.on('select2:select', function () {

        }).on('select2:select', function () {
            $(this).blur();
        }).on('select2:open', function (e) {
            (document.querySelector(`[aria-controls="select2-${e.target.id}-results"]`) as HTMLElement).focus();
        });
    }

    private async _onBtnSubmitClick(ev: Event, formElement: any) {
        ev.preventDefault();
        if (!formElement.valid()) {
            return;
        }
        var formData = new FormData(formElement[0] as HTMLFormElement);
        var commuteDaysAWeek = Number(formData.get("commuteDaysAWeek") as string);
        var commuteFrequency = Number(formData.get("commuteFrequency") as string);
        var distance = formData.get("commuteDistance") as string;
        var commuteDistance = Number(distance.replace(',', '.'));
        var regionId = $("#region-search").val() as string;
        var employerId = $("#employer-search").val() as string;

        var data: RegistracijaTretjiKorakModel = {
            commuteDaysAWeek: commuteDaysAWeek,
            commuteFrequency: commuteFrequency,
            commuteDistance: commuteDistance,
            regionId: regionId,
            employerId: employerId
        };
        await this._userApiClient.registracijaTretjiKorakShrani(data)
            .then((odgovor: boolean) => {
                if (odgovor) {
                    window.location.href = "#/profil";
                }
            })
            .catch(() => {
                this._notyf.error(this._translationService.currentTranslations["UnexpectedError"]);
            });
    }

    private _dodajValidacijskeFunkcije(): void {
        $.validator.addMethod("numberIsValid", function (number) {
            number = number.replace(',', '.');
            var check = Number(number);
            if (isNaN(check)) {
                return false;
            }
            return true;
        });
    }
}