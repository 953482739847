import { Container } from "inversify";
import { Authenticator } from "./authenticator";

export class OpenIdLoginHandler {
	public static async handleLogin(serviceProvider: Container): Promise<boolean> {
		var authenticator = serviceProvider.get<Authenticator>("Authenticator");
		var url = new URL(location.href);
		var query = url.searchParams;
		var state = query.get("state");
		if (state) {
			await authenticator._handleLoginResponse();
		}
		return true;
	}
}