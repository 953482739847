import { injectable } from "inversify";
import * as Mustache from "mustache";
import { TranslationService } from "../ts/translation-service";
import template from "./content-view.html";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import { HomeApiClient } from "../ts/clients/home-api-client";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";

@injectable()
export class ContentView {
    private _desktopApiClient: HomeApiClient;
    private _umbracoApiClient: UmbracoApiClient;
    private _translationService: TranslationService;
    private _translations: any;

    public constructor(desktopApiClient: HomeApiClient,
        umbracoApiClient: UmbracoApiClient,
        translationService: TranslationService,
        private currentUserAccessor: CurrentUserAccessor) {        
        this._desktopApiClient = desktopApiClient;
        this._umbracoApiClient = umbracoApiClient;
        this._translationService = translationService;
        this._translations = null;
    }

    public async load(): Promise<void> {
        try {
            console.log(this._umbracoApiClient);
            var response = await this._desktopApiClient.get();
            //var izzivi = await this._umbracoApiClient.getIzzivi();
            await this._renderData(response.data);
        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _renderData(data: string): Promise<void> {

        // Build a view model from the API data
        this._translations = this._translationService.currentTranslations;
        var currentUser = await this.currentUserAccessor.getUser();
        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            "imageKuponUrl": "../img/icon-kuponi.png",
            "imageKodaUrl": "../img/koda.jpg",
            "text": data,
            translations: this._translations,
            currentUser: currentUser
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);
        
    }    
}