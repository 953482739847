import { injectable } from "inversify";
import * as Mustache from "mustache";
import template from "./faq-view.html";
import helpModal from "./help-modal.html";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";
import { HelpModel } from "../ts/models/help-model";
import { MailchimpApiClient } from "../ts/clients/mailchimp-api-client";
import { Notyf } from "notyf";

@injectable()
export class FaqView {
    private _apiClient: UmbracoApiClient;

    public constructor(apiClient: UmbracoApiClient, private mailchimpApiClient: MailchimpApiClient, private notyf: Notyf) {        
        this._apiClient = apiClient;
    }

    public async load(): Promise<void> {
        try {
            var pogostaVprasanja = await this._apiClient.getPogostaVprasanja();
            await this._renderData(pogostaVprasanja); 
            $("#btn-stopite-v-stik").on("click", async () => {
                await this._initHelp();
            });

        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _initHelp(): Promise<void> {
        var self = this;

        const helpViewModel = {} as any;

        const modalHtml = Mustache.render(helpModal, helpViewModel);
        $('#helpWrap').html(modalHtml);

        $('#helpModal').modal("show");

        var formElement = $("#helpForm");
        $.validator.unobtrusive.parse(formElement);
        formElement.validate();

        formElement.on("submit", function (ev) {
            ev.preventDefault();
            if (!formElement.valid()) {
                return;
            }

            var request: HelpModel = {
                name: formElement.find("#name").val() as string,
                email: formElement.find("#email").val() as string,
                message: formElement.find("#message").val() as string,
                agreeWithTerms: formElement.find("#agreeWithTerms").prop('checked')
            };


            self.mailchimpApiClient.submitHelp(request)
                .then(async (data) => {
                    if (data && data.success) {
                        self.notyf.success("Podatki uspešno poslani!");
                        $("#helpModal").modal("hide");
                    }
                    else if (data && data.message) {
                        self.notyf.error(data.message);
                    }
                    else {
                        self.notyf.error("Prišlo je do napake!");

                    }
                })
                .catch(() => {
                    self.notyf.error("Prišlo je do napake");
                });
        });
    }

    private async _renderData(data: any): Promise<void> {

        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            "imageKuponUrl": "../img/icon-kuponi.png",
            "imageKodaUrl": "../img/koda.jpg",
            "text": data,
            pogostaVprasanja: data
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);      
    }   
}
