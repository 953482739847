import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";
import { ConfirmTeamMemberRequest } from "../models/confirm-team-member-request";
import { TeamRankResponse } from "../models/TeamRankResponse";

@injectable()
export class TeamApiClient extends ApiClientBase {
    public name = "TeamApiClient";
    public constructor(
        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async getUserTeam(): Promise<any> {
        return await this._callApi('/Team/GetUserTeam', 'GET', null, true) as any;
    }

        
    public async getTeamTopRank(): Promise<Array<TeamRankResponse>> {
        return await this._callApi('/Team/GetTopRank', 'GET', null, false) as Array<TeamRankResponse>;
    }

          
    public async getTeamRank(): Promise<Array<TeamRankResponse>> {
        return await this._callApi('/Team/GetRank', 'GET', null, false) as Array<TeamRankResponse>;
    }


    public async addTeam(teamName: string): Promise<any> {
        return await this._callApi('/Team/AddTeam', 'POST', JSON.stringify(teamName), true, "") as any;
    }

    public async editTeam(teamName: string): Promise<any> {
        return await this._callApi('/Team/EditTeam', 'POST', JSON.stringify(teamName), true, "") as any;
    }

    public async sendInvitation(emails: string): Promise<any> {
        return await this._callApi('/Team/SendInvitationTeamMember', 'POST', JSON.stringify(emails), true, "") as any;
    }

    public async confirmTeamMember(data: ConfirmTeamMemberRequest): Promise<any> {
        return await this._callApi('/Team/ConfirmTeamMember', 'POST', JSON.stringify(data), true, "") as any;
    }

    public async removeUserFromTeam(id: number): Promise<any> {
        return await this._callApi('/Team/RemoveUserFromTeam/' + id, 'POST', null, true, "") as any;
    }

    public async deleteTeam(): Promise<any> {
        return await this._callApi('/Team/DeleteTeam', 'POST', null, true, "") as any;
    }

    public async changeAvatar(profileSaveModel: FormData): Promise<any> {
        return await this._callApiWithFormData('/Team/ChangeTeamAvatar', 'POST', profileSaveModel, true) as any;
    }

}