import { inject, injectable } from "inversify";
import { ApiClientBase } from "../../../../skupno/src/ts/api-client/api-client";
import { Authenticator } from "../../../../skupno/src/ts/authentication/authenticator";
import { Configuration } from "../../../../skupno/src/ts/configuration/configuration";

@injectable()
export class WebPushApiClient extends ApiClientBase {
    public name = "WebPushApiClient";
    public constructor(

        @inject("Configuration") configuration: Configuration,
        @inject("Authenticator") authenticator: Authenticator) {
        super(configuration, authenticator);
    }

    public async refreshToken(token:string): Promise<boolean> {
        return await this._callApi(`/WebPush/RefreshToken/${token}`, 'POST', null, false) as boolean;
    }
    public async deleteToken(token: string): Promise<boolean> {
        return await this._callApi(`/WebPush/DeleteToken/${token}`, 'POST', null, false) as boolean;
    }
}