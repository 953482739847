import { injectable } from "inversify";
import * as Mustache from "mustache";
import template from "./pogoji-uporabe-view.html";
import { UmbracoApiClient } from "../ts/clients/umbraco-api-client";
import { PogojiUporabe } from "../ts/models/pogoji-uporabe";

@injectable()
export class PogojiUporabeView {
    private _apiClient: UmbracoApiClient;

    public constructor(apiClient: UmbracoApiClient) {        
        this._apiClient = apiClient;
    }

    public async load(): Promise<void> {
        try {

            let response = await this._apiClient.getPogojiUporabe();
            await this._renderData(response);                                              

        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _renderData(data: PogojiUporabe): Promise<void> {
        // Build a view model from the API data
        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            "imageKuponUrl": "../img/icon-kuponi.png",
            "imageKodaUrl": "../img/koda.jpg",
            "pogoji": data
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);

        // Set SEO
        $("#meta-description")[0].setAttribute("content", data.SeoDescription);
        $("#meta-og-title")[0].setAttribute("content", data.SeoTitle);
        $("#meta-og-description")[0].setAttribute("content", data.SeoDescription);
        $("#meta-og-image")[0].setAttribute("content", data.SeoImage);
        $("#meta-twitter-title")[0].setAttribute("content", data.SeoTitle);
        $("#meta-twitter-description")[0].setAttribute("content", data.SeoDescription);
        $("#meta-twitter-image")[0].setAttribute("content", data.SeoImage);
        var currentUrl = window.location.href;
        $("#meta-og-url")[0].setAttribute("content", currentUrl);
        $("#meta-twitter-url")[0].setAttribute("content", currentUrl);

    }  
}
