import { NavigationNode } from "./models/navigation-node";

var rootNodes: Array<NavigationNode> = [
    { "title": "Desktop", "class": "namizje", "url": "/#" },
    {
        title: "Users",
        "class": "management",
        url: "#/uporabniki",
        permissions: 778
    },  
];
export default rootNodes;