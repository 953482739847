import { injectable } from "inversify";
import * as Mustache from "mustache";
import template from "./temp-view.html";
import { Notyf } from "notyf";
import { FullscreenLoader } from "./fullscreen-loader";
import { MailchimpApiClient } from "../ts/clients/mailchimp-api-client";

@injectable()
export class TempView {
    private _apiClient: MailchimpApiClient;

    public constructor(apiClient: MailchimpApiClient,
        private notyf: Notyf,
        private loader: FullscreenLoader) {        
        this._apiClient = apiClient;
    }

    public async load(): Promise<void> {
        try {
            await this._renderData();                                              

        } catch (e) {
            console.log(e);
            // Clear previous content on error
            $('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _renderData(): Promise<void> {

        // Build a view model from the API data
        const viewModel = {
            "imageUrl": "../img/icon-logout.svg",
            "imageKuponUrl": "../img/icon-kuponi.png",
            "imageKodaUrl": "../img/koda.jpg"
        } as any;

        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        $('#main').html(html);

        var formElement = $("#newsletterForm");
        $.validator.unobtrusive.parse(formElement);
        formElement.validate();

        formElement.on("submit", (ev) => {
            ev.preventDefault();
            if (!formElement.valid()) {
                return;
            }
            this.loader.show();
            var email = formElement.find("[name=email]").val() as string;
            this._apiClient.mailchimpSubscribe(email)
                .then(() => {
                    formElement.find("[name=email]").val('');
                    formElement.find("[name=agreeWithTerms]").prop("checked", false);
                    this.notyf.success("Uspešno ste se prijavili na e novice");
                    this.loader.hide();
                })
                .catch(() => {
                    this.notyf.error("Prišlo je do napake");
                    this.loader.hide();
                });
        });  

    }      
}