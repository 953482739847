import { inject, injectable, interfaces } from "inversify";
import { PageBase } from "../../../../skupno/src/ts/routing/page-base";
import { MainLayout } from "../../views/main-layout";
import { Router } from "../../../../skupno/src/ts/routing/router";
import { TranslationService } from "../translation-service";
import { Notyf } from "notyf";
import { ErrorLoadingView } from "../../views/error-loading-view";
import { TempView } from "../../views/temp-view";
import { FullscreenLoader } from "../../views/fullscreen-loader";
import { MailchimpApiClient } from "../clients/mailchimp-api-client";

@injectable()
export class TempPage extends PageBase
{   
    public name = "TempPage";
    private _apiClient: MailchimpApiClient;
    private _mainLayoutProvider: interfaces.Provider<MainLayout>;
    private _router: Router;
    private _homeView: TempView;
    private _translationService: TranslationService;
    private _notyf: Notyf;
    private _errorLoadingView: ErrorLoadingView;
    private _fullscreenLoader: FullscreenLoader;

    public constructor(@inject("Provider<MainLayout>") mainLayoutProvider: interfaces.Provider<MainLayout>,
        @inject("Router") router: Router,
        @inject("TranslationService") translationService: TranslationService,        
        @inject("MailchimpApiClient") apiClient: MailchimpApiClient,
        @inject("Notyf") notyf: Notyf,
        @inject("FullscreenLoader") fullscreenLoader: FullscreenLoader
    ) {
        super();
        this._mainLayoutProvider = mainLayoutProvider;
        this._router = router;
        this._translationService = translationService;
        this._notyf = notyf;
        this._apiClient = apiClient;
        this._fullscreenLoader = fullscreenLoader;
        this._homeView = new TempView(this._apiClient, this._notyf, this._fullscreenLoader);
        this._errorLoadingView = new ErrorLoadingView(this._translationService);
    }

    public async execute(): Promise<boolean> {
        var url = this._router.getVirtualUrl();
        if (url.pathname != "" && !url.pathname.startsWith("/temp")) {
            return true;
        }
        console.log(this._mainLayoutProvider);
        var mainLayout = await this._mainLayoutProvider() as MainLayout;
        try {
            //var translations = this._translationService.currentTranslations;
            //mainLayout.pageTitle = translations.Desktop;
            //mainLayout.htmlTitle = translations.Desktop;
            //mainLayout.headerUserViewHide();
            //mainLayout.profileSidebarViewShow();
            mainLayout.hideFooter();
            mainLayout.hideHeader();
            await this._homeView.load();
        } catch (e) {
            // Report failures
            this._notyf.error(this._translationService.currentTranslations.LoadingError);
            this._errorLoadingView.load();
        }

        return false;
    }
}